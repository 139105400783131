// @flow
import React, { useContext, useState } from "react";
import "./header.css";
import Images from "../../assets";
import {
  AppBar,
  Backdrop,
  Badge,
  ClickAwayListener,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import Search from "../search/search";
import Logo from "../logo/logo";
import AccountMenu from "../accountMenu/accountMenu";
import Box from "@mui/material/Box";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import {
  ROUTE_PAGE_CHAT,
  ROUTE_PAGE_COMPANIES,
  ROUTE_PAGE_GROUPS,
  ROUTE_PAGE_HOME,
  ROUTE_PAGE_JOBS, ROUTE_PAGE_MOODLE, ROUTE_PAGE_NOTIFICATIONS_LIST,
} from "../../routers/routes";
import { useHistory } from "react-router-dom";
import theme from "../../theme/theme";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {
  BankIcon,
  BuildingIcon,
  ChatNetworkIcon,
  HomeIcon,
  MessagesIcon, NotificationIcon,
  SearchIcon,
} from "../../assets/toplayout/icons";
import { LocationContext } from "../../App";
import { locationPath, locations } from "../../helpers/constants";
import Menu from "@mui/material/Menu";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import { HEADER } from "../../locales/components/namespaces";

type Props = {};

const Header = (props: Props) => {
  const { t } = useTranslation(HEADER);
  const history = useHistory();
  const { value } = useContext(LocationContext);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const appBar = {
    position: "fixed",
    color: "inherit",
    elevation: 0,
    sx: {
      zIndex: 1200,
      width: "100%",
      backgroundColor: "#FFFFFF",
      position: "relative",
    },
  };





  const notificationsData = [
    { id: 1, message: 'New message from Alice', read: false },
    { id: 2, message: 'Meeting at 3 PM', read: false },
    { id: 3, message: 'Update your profile', read: true },
  ];
  const [notifications, setNotifications] = useState(notificationsData);
  const [anchorEl, setAnchorEl] = useState(null);

  // Calculate unread notifications count
  const unreadCount = notifications.filter((notif) => !notif.read).length;

  // Handle opening the notification dropdown
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the dropdown
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Mark all notifications as read when clicked
  const handleMarkAllAsRead = () => {
    const updatedNotifications = notifications.map((notif) => ({
      ...notif,
      read: true,
    }));
    setNotifications(updatedNotifications);
    handleClose();
  };
  return (
    <AppBar {...appBar}>
      <Toolbar variant={"dense"} sx={{ justifyContent: "space-between" }}>
        <Logo />
        <Search />
        <Box className={"icons"}>
          {!matches && (
            <>
              <IconButton
               onClick={() => history.push(getRouteUrl(ROUTE_PAGE_MOODLE))}
              >
                <img alt="bank" src={Images.bank} />
              </IconButton>
              <IconButton
                onClick={() => history.push(getRouteUrl(ROUTE_PAGE_JOBS))}
              >
                <img alt="search" src={Images.search_alt} />
              </IconButton>
              {/*<IconButton color="inherit" onClick={handleClick}>*/}
              {/*  <Badge badgeContent={unreadCount} color="error">*/}
              {/*    <NotificationIcon/>*/}
              {/*  </Badge>*/}
              {/*</IconButton>*/}
            </>
          )}

          <Box
            sx={{
              width: "55px",
              marginTop: "-3px",
            }}
          >
            <AccountMenu />
          </Box>
        </Box>
      </Toolbar>

      {matches && (
        <Toolbar variant={"dense"} sx={{ justifyContent: "space-between" }}>
          <Box className="secondBarIcons">
            <IconButton
              className={value && locations[value] === locationPath.root ? "active" : ""}
              onClick={() => history.push(getRouteUrl(ROUTE_PAGE_HOME))}
            >
              <HomeIcon  style={{width:'26px',height:"26px"}} />
            </IconButton>
            <IconButton
              onClick={() => history.push(getRouteUrl(ROUTE_PAGE_COMPANIES))}
              className={value && locations[value] === locationPath.companies ? "active" : ""}
            >
              <BuildingIcon style={{width:'26px',height:"26px"}} />
            </IconButton>
            <IconButton
              onClick={() => history.push(getRouteUrl(ROUTE_PAGE_GROUPS))}
              className={value && locations[value] === locationPath.groups ? "active" : ""}
            >
              <ChatNetworkIcon  style={{width:'26px',height:"26px"}} />
            </IconButton>

            <IconButton
              onClick={() => history.push(getRouteUrl(ROUTE_PAGE_MOODLE))}
              className={value && locations[value] === locationPath.education ? "active" : ""}
            >
              <BankIcon />
            </IconButton>
            <IconButton
              onClick={() => history.push(getRouteUrl(ROUTE_PAGE_JOBS))}
              className={value && locations[value] === locationPath.jobs ? "active" : ""}
            >
              <SearchIcon  style={{width:'26px',height:"26px"}} />
            </IconButton>
            <IconButton
              onClick={() => history.push(getRouteUrl(ROUTE_PAGE_CHAT))}
              className={value && locations[value] === locationPath.chat ? "active" : ""}
            >
              <MessagesIcon
                style={{width:'26px',height:"26px"}} />
            </IconButton>
            <IconButton color="inherit" onClick={() => history.push(getRouteUrl(ROUTE_PAGE_NOTIFICATIONS_LIST))}
                        className={value && locations[value] === locationPath.notification ? "active" : ""}>
              <Badge badgeContent={unreadCount} color="error">
                <NotificationIcon/>
              </Badge>
            </IconButton>
          </Box>
        </Toolbar>
      )}
      {/* Dropdown Menu for Notifications */}

      <Menu
        hideBackdrop={true}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 300, // Set max height for scrolling if there are too many notifications
            width: '250px',
          },
        }}
      >
    <ClickAwayListener style={{position: "fixed",zIndex:1301}} onClickAway={handleClose}>
        {/* Display notifications */}
        <List>
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <ListItem
                key={notification.id}
                style={{ backgroundColor: notification.read ? '#f5f5f5' : '#e3f2fd' }}
              >
                <ListItemText primary={notification.message} />
              </ListItem>
            ))
          ) : (
            <MenuItem>{t("All notifications")}</MenuItem>
          )}
        </List>
      </ClickAwayListener>

        {/* Mark all as read button */}
        <MenuItem onClick={()=>history.push(getRouteUrl(ROUTE_PAGE_NOTIFICATIONS_LIST))}>{t("All notifications")}</MenuItem>
      </Menu>
    </AppBar>
  );
};

export default Header;
