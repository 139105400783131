import axios from "axios";

export const getGroups = async ({ queryKey }) => {
  const [_, accessCode] = queryKey;
  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=602a&scode=" + accessCode,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const addGroup = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=601a&scode=" + values.accessCode,
    values.formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getPosts = async ({queryKey }) => {
    const [_, accessCode] = queryKey
    const response = await axios.get(
        "https://dev.ostrialinked.gr/api/api.php?q=9s2e1&scode=" + accessCode,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        },
    );
    return response.data
}
export const userSpecificTypePosts = async ({queryKey }) => {
    const [_, accessCode,type,id] = queryKey

    const response = await axios.post(
        "https://dev.ostrialinked.gr/api/api.php?q=9s2e11&scode=" + accessCode,
        {id: id,type},
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        },
    );
    return response.data
}

export const getPostComments = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=9k3&scode=" + values.accessCode,
    { id_post: values.postId },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const addPostComment = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=9k1&scode=" + values.accessCode,
    {
      comment: values.comment,
      id_post: values.postId,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const deletePostComments = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=9k2&scode=" + values.accessCode,
    {
      id_comment: values.commentId,
      id_post: values.postId,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const deleteGroup = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=605a&scode=" + values.accessCode,
    { id: values.id },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};
export const deleteCompany = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=705a&scode=" + values.accessCode,
    { id: values.id },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const becomeMember = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=607b&scode=" + values.accessCode,
    { id: values.id },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};
export const becomeCompanyMember = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=707b&scode=" + values.accessCode,
    { id: values.id },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getGroup = async ({ queryKey }) => {
  const [_, accessCode, groupCode] = queryKey;
  const response = await axios.post(
    `https://dev.ostrialinked.gr/api/api.php?q=603a&scode=` + accessCode,
    { id: groupCode },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getGroupMembers = async ({ queryKey }) => {
  const [_, accessCode, groupCode] = queryKey;
  const response = await axios.post(
    `https://dev.ostrialinked.gr/api/api.php?q=602b&scode=` + accessCode,
    { id: groupCode },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};
export const deletePost = async (values) => {
  const response = await axios.post(
    `https://dev.ostrialinked.gr/api/api.php?q=9ad&scode=` + values.accessCode,
    { id_post: values.id_post },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getMyGamification = async ({ queryKey }) => {
  const [_, accessCode, userId] = queryKey;
  const response = await axios.post(
    `https://dev.ostrialinked.gr/api/api.php?q=200a&scode=` + accessCode,
    { idu: userId },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getUserGamification = async ({ queryKey }) => {
  const [_, accessCode, userId, idx] = queryKey;
  const response = await axios.post(
    `https://dev.ostrialinked.gr/api/api.php?q=200a&scode=` + accessCode,
    { idu: userId, idx: idx },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const addCompany = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=701a&scode=" + values.accessCode,
    values.formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getCompanies = async ({ queryKey }) => {
  const [_, accessCode] = queryKey;
  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=702a&scode=" + accessCode,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};
export const getCompany = async ({ queryKey }) => {
  const [_, accessCode, id] = queryKey;
  const response = await axios.post(
    `https://dev.ostrialinked.gr/api/api.php?q=703a&scode=` + accessCode,
    { id: id },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const likePost = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=401a&scode=" + values.accessCode,
    { id_post: values.postId },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getFullPost = async ({ queryKey }) => {
  const [_, accessCode, id] = queryKey;
  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=9s2&scode=" +
      accessCode +
      "&id_post=" +
      id,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getAllUsers = async ({ queryKey }) => {
  const [_, accessCode] = queryKey;
  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=4&scode=" + accessCode,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getConversation = async ({ queryKey }) => {
  const [_, accessCode, selectedMsgReceiverId] = queryKey;

  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=msg05&scode=" + accessCode,
    { idr: selectedMsgReceiverId },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getHistoryConversation = async ({ queryKey }) => {
  const [_, accessCode] = queryKey;

  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=msg07&scode=" + accessCode
  );
  return response.data;
};

export const sendTextMessage = async (values) => {
  const [_, accessCode, selectedMsgReceiverId, textMessage] = values;

  const response = await axios.post(
    ` https://dev.ostrialinked.gr/api/api.php?q=msg01&scode=` + accessCode,
    { idr: selectedMsgReceiverId, message: textMessage },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const askOwnerPermissionForAds = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=710a&scode=" + values.accessCode,
    { id_company: values.companyId },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getPersonalGroups = async ({ queryKey }) => {
  const [_, accessCode] = queryKey;
  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=606a&scode=" + accessCode,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data
};

export const getFiles = async ({queryKey }) => {
  const [_, accessCode] = queryKey
  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=18a&scode=" + accessCode,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    },
  );
  return response.data
}

export const getFriendsFiles = async ({queryKey }) => {
  const [_, accessCode,id] = queryKey
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=18b&scode=" + accessCode,
    {id: id},
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    },
  );
  return response.data
}

export const getAdsCategory = async ({queryKey }) => {
  const [_, accessCode] = queryKey
  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=aggcat&scode=" + accessCode,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    },
  );
  return response.data
}

export const addCompanyAd = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=agg01&scode=" + values.accessCode,
    values.form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    },
  );
  return response.data
}

export const getCompanyAds = async ({queryKey }) => {
  const [_, accessCode, id] = queryKey
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=agg02&scode=" +accessCode,
    { id_company:id},
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    },
  );
  return response.data
}

export const deleteAdCompany = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=agg05&scode=" + values.accessCode,
    { id: values.id },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const editAdCompany = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=agg06&scode=" + values.accessCode,
    values.form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const activeAdCompany = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=agg08&scode=" + values.accessCode,
    {id: values.id} ,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const deactivateAdCompany = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=agg07&scode=" + values.accessCode,
    {id: values.id} ,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const jobApply = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=ait01&scode=" + values.accessCode,
    values.form ,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getAdCompany = async ({queryKey }) => {
  const [_, accessCode,id] = queryKey
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=agg04&scode=" + accessCode,
    {id: id} ,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getCompanyFiles = async ({queryKey }) => {
  const [_, accessCode,id] = queryKey
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=19a&scode=" + accessCode,
    {id_company:id},
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    },
  );
  return response.data
}

export const getAdsClaimsForAd = async ({queryKey }) => {
  const [_, accessCode,id] = queryKey
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=ait02&scode=" + accessCode,
    {id_agg:id},
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    },
  );
  return response.data
}

export const getAdsClaimsForCompany = async ({queryKey }) => {
  const [_, accessCode,id] = queryKey
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=ait06&scode=" + accessCode,
    {id_company:id},
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    },
  );
  return response.data
}

export const getBiosBySearch = async ({queryKey }) => {
  const [_, accessCode,searchValue] = queryKey

  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=sbio01&scode=" + accessCode,
    {keywords:searchValue},
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    },
  );
  return response.data
}

export const getJobsBySearch = async ({queryKey }) => {
  const [_, accessCode,typeSearch,locationSearch] = queryKey

  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=agg09&scode=" + accessCode,
    {searchtext:typeSearch, searchcity:locationSearch},
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    },
  );
  return response.data
}

export const getRandomJobs = async ({queryKey }) => {
  const [_, accessCode] = queryKey

  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=agg10&scode=" + accessCode,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    },
  );
  return response.data
}

export const changeGroupPhoto = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=608a2&scode=" + values.accessCode,
    values.form ,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const uploadPersonalCv = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=8a3&scode=" + values.accessCode,
    values.form ,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getPersonalCv = async ({ queryKey} ) => {
  const [_, accessCode] = queryKey
  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=8a4&scode=" + accessCode,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getMoodleLessons = async ({ queryKey} ) => {
  const [_, accessCode] = queryKey
  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=mdl01&scode=" + accessCode,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const changeCompanyLogo = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=707a3&scode=" + values.accessCode,
    values.form ,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const changeCompanyBanner = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=707a2&scode=" + values.accessCode,
    values.form ,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const changeGroupDesciption = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=608a1&scode=" + values.accessCode,
    values.form ,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const editCompany = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=707a1&scode=" + values.accessCode,
    values.formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const editProfileDescription = async (values) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=8a2&scode=" + values.accessCode,
    values.formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getRss = async ({ queryKey} ) => {
  const [_, accessCode] = queryKey
  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=rss&scode=" + accessCode,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getFriendRecommendations = async ({ queryKey} ) => {
  const [_, accessCode,id] = queryKey
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=10c&scode=" + accessCode,
    {"idu":id},
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getCategoryMoodle = async ({ queryKey} ) => {
  const [_, accessCode] = queryKey
  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=mdl02&scode=" + accessCode,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getUserCategoryMoodle = async ({ queryKey} ) => {
  const [_, accessCode] = queryKey
  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=categ02&scode=" + accessCode,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const addUserCategoryMoodle = async (values ) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=categ01&scode=" + values.accessCode,
    values.form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const addRecommendation = async (values ) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=10&scode=" + values.accessCode,
    values.form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const editRecommendation = async (values ) => {
  const response = await axios.post(
    "https://dev.ostrialinked.gr/api/api.php?q=10a&scode=" + values.accessCode,
    values.form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getRecommendations = async ({ queryKey} ) => {
  const [_, accessCode] = queryKey
  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=10b&scode=" + accessCode,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const deleteAccountReq = async (values) => {
  const response = await axios.get(
    "https://dev.ostrialinked.gr/api/api.php?q=102&e=" + values.email,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};