// @flow
import React from "react";
import Box from "@mui/material/Box";
import "./centerTab.css";
import { useTranslation } from "react-i18next";
import { PAGE_PERSONAL } from "../../../locales/pages/namespaces";
import { Button, IconButton, ListItemButton, Typography, useMediaQuery } from "@mui/material";
import InformationTab from "../rightTabs/informationTab";
import theme from "../../../theme/theme";
import { daysPassed, findYoutubeUrl, linkify } from "../../../helpers/helpers";
import MediaCard from "../../mediaCard/mediaCard";
import PostComponent from "../../postComponent/postComponent";
import useUserData from "../../../hooks/user/useUserData";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addPostComment,
  deletePost,
  deletePostComments, getCompany,
  getPostComments, likePost,
  userSpecificTypePosts,
} from "../../../api/requests";
import Modal from "@mui/material/Modal";
import CardMedia from "@mui/material/CardMedia";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import ContentLoader from "../../contentLoader/contentLoader";
import OstriaModal from "../../ostriaModal/ostriaModal";
import { GENERIC } from "../../../locales/components/namespaces";
import useUserAction from "../../../hooks/user/useUserAction";
import { Check, Close } from "@mui/icons-material";


type Props = {};

const CenterTab = (props: Props) => {
  const { t } = useTranslation(PAGE_PERSONAL);
  const { t: g } = useTranslation(GENERIC);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { accessCode, userId, isModalOpen: isDeleteModalOpen } = useUserData();
  const { openModal, closeModal } = useUserAction();
  const { id } = useParams();
  const { data: companyData } = useQuery(["getCompany", accessCode, id], getCompany);
  const { data: companyPosts, isLoading, refetch } = useQuery({
    queryKey: ["userSpecificTypePosts", accessCode, "company", id],
    queryFn: userSpecificTypePosts,
  });
  const [isModalOpen, setOpenModal] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const queryClient = useQueryClient();
  const onSubmit = (values, formikHelpers) => {
    if (values?.keimeno?.value?.length > 0) {
      addPostCommentsAction({
        postId: values.keimeno.postId,
        comment: values.keimeno.value,
        accessCode: accessCode,
      });
      formikHelpers.setValues({});
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = ({ postId, e }) => {
    setAnchorEl({ postId, anchorEl: e.currentTarget });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { mutate: getPostCommentsAction } = useMutation({
    mutationFn: getPostComments,
    onSuccess: (data, variables, context) => {
      let newPosts = companyPosts;
      if (data[0] && data[0].id_post) {
        const obj = newPosts.find(item => item.id === variables.postId);
        if (obj) {
          obj["comments"] = data;
        }
      }
      queryClient.setQueryData(["userSpecificTypePosts", accessCode, "company", id], newPosts);
    },
  });

  const { mutate: addPostCommentsAction } = useMutation({
    mutationFn: addPostComment,
    onSuccess: (data, variables, context) => {
      let newPosts = companyPosts;

      const obj = newPosts.find(item => item.id === variables.postId);
      if (obj) {
        obj.commentsNumber = (parseInt(obj.commentsNumber) + 1).toString();
      }
      queryClient.setQueryData(["userSpecificTypePosts", accessCode, "company", id], newPosts);

    },
  });

  const { mutate: deletePostCommentsAction } = useMutation({
    mutationFn: deletePostComments,
    onSuccess: (data, variables, context) => {
      let newPosts = companyPosts;

      if (data.deleted === "1") {
        const obj = newPosts.find(item => item.id === variables.postId);
        if (obj) {
          const comments = obj["comments"].filter(c => c.id_comment !== variables.commentId);
          obj["comments"] = comments;
          obj.commentsNumber = (parseInt(obj.commentsNumber) - 1).toString();
        }
      }
      queryClient.setQueryData(["userSpecificTypePosts", accessCode, "company", id], newPosts);
    },
  });
  const { mutate: deletePostAction } = useMutation({
    mutationFn: deletePost,
    onSuccess: (data, variables, context) => {
      refetch();
    },
  });
  const { mutate: likePostAction } = useMutation({
    mutationFn: likePost,
    onSuccess: (data, variables, context) => {
      let newPosts = companyPosts;
      let newPost = null;
      if (variables.postId) {
        newPost = newPosts.find(item => item.id === variables.postId);
        if (data.like === "added") {
          newPost.ihadLike = "1";
          newPost.likesNumber = (parseInt(newPost.likesNumber) + 1).toString();
        } else {
          newPost.ihadLike = "0";
          newPost.likesNumber = (parseInt(newPost.likesNumber) - 1).toString();
        }
      }
      queryClient.setQueryData(["userSpecificTypePosts", accessCode, "company", id], newPosts);
    },
  });

  const deleteAction = () => {
    if (isDeleteModalOpen.commentId) {
      deletePostCommentsAction(isDeleteModalOpen);
    } else {
      deletePostAction(isDeleteModalOpen);
    }
    closeModal();
  };
  return (
    <>
      <Box className="centerLeftTab"
           sx={{
             width: {
               xs: "100%",
               sm: "100%",
               md: "60%",
               lg: "60%",
             },
           }}
      >
        {isLoading === false ?
          <>
            {companyData && companyData?.isOwner === "true" && <PostComponent />}
            {companyPosts?.map(postData => {
              const linkifiedText = linkify(postData.description);
              const findYoutubeUrlString = findYoutubeUrl(postData.description);
              return (
                <Box margin={"10px 4px"}>
                  <MediaCard
                    linkifiedText={linkifiedText}
                    findYoutubeUrlString={findYoutubeUrlString}
                    type={postData.type}
                    postUserId={postData?.user?.id}
                    commentsNumber={postData?.commentsNumber}
                    likesNumber={postData?.likesNumber}
                    userId={userId}
                    deletePostComment={openModal}
                    handleClick={handleClick}
                    comments={postData.comments}
                    onSubmit={onSubmit}
                    getPostComments={getPostCommentsAction}
                    postId={postData?.id}
                    description={postData?.description}
                    date={daysPassed(postData?.public_date) + "d"}
                    assets={postData.contents?.length > 1 ? postData.contents.map(x => ({
                      url: `https://dev.ostrialinked.gr/api/uploads/${x.filename}`,
                      type: x.type,
                      filename: x.filename,
                    })) : null}
                    asset={postData.contents?.length === 1 ?
                      {
                        url: `https://dev.ostrialinked.gr/api/uploads/${postData.contents[0].filename}`,
                        type: postData.contents[0].type,
                        filename: postData.contents[0].filename,
                      } : null}
                    setOpenModal={setOpenModal}
                    ihadLike={postData?.ihadLike}
                    likePost={likePostAction}
                    imageProfile={postData.company.id_user === postData.user.id ? postData.company.logo : postData?.user?.image_profile}
                    user={postData.company.id_user === postData.user.id ? {
                      ...postData.user,
                      firstname: postData.company.title,
                      lastname: "",
                      companyId: postData.company.id,
                    } : postData.user}
                  />
                </Box>
              );
            })}
            <OstriaModal
              open={Boolean(isModalOpen)}
              onClose={() => {
                setOpenModal(null);
              }}
            >
              <CardMedia
                component="img"
                image={isModalOpen}
                alt={isModalOpen}
              />
            </OstriaModal>
            <Menu
              id={`menu`}
              anchorEl={anchorEl?.anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  onClick={() => {
                    openModal({ id_post: anchorEl.postId, accessCode });
                    setAnchorEl(null);
                  }}
                  primary={t("delete")}
                />
              </ListItemButton>
            </Menu>
          </>
          :
          <ContentLoader loading={isLoading}></ContentLoader>
        }
        <OstriaModal
          open={Boolean(isDeleteModalOpen) && !isDeleteModalOpen.id}
          onClose={closeModal}
        >
          <Box
            sx={{
              height: "auto",
              padding: "16px",
              margin: "auto",
            }}
          >
            <Typography
              sx={{
                color: "#050505",
                fontFamily: "\"Segoe UI-Bold\", Helvetica",
                fontSize: { xs: "15px", md: "21px" },
                fontWeight: "700",
                marginBottom: { xs: "5px", md: "10px" },
                textAlign: "center",
              }}
            >
              {g("Are you sure?")}
            </Typography>
            <Box className="reportButtons">
              <IconButton onClick={deleteAction}>
                <Check color="success" />
                {g("yes")}
              </IconButton>
              <IconButton onClick={closeModal}>
                <Close sx={{ color: "red" }} />
                {g("no")}
              </IconButton>
            </Box>
          </Box>
        </OstriaModal>
      </Box>
      {!matches && (
        <Box sx={{ width: "40%" }}>
          <InformationTab />
        </Box>
      )}
    </>

  );
};

export default CenterTab;