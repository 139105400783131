import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { PictureAsPdf } from "@mui/icons-material";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import { FormControl, Input, TextField } from "@mui/material";
import { Formik, Form } from "formik";
import SendIcon from "@mui/icons-material/Send";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import useUserData from "../../hooks/user/useUserData";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import {
  ROUTE_PAGE_COMPANY_CENTER,
  ROUTE_PAGE_FRIEND,
  ROUTE_PAGE_FULL_POST,
  ROUTE_PAGE_PERSONAL,
} from "../../routers/routes";
import { useHistory } from "react-router-dom";
import { COMPONENT_POSTCARD } from "../../locales/components/namespaces";
import { findYoutubeUrl, linkify } from "../../helpers/helpers";
import { useEffect, useState } from "react";
import axios from "axios";
import  "./mediaCard.css"


interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const MediaCard = ({
                     description,
                     date,
                     assets,
                     asset,
                     setOpenModal,
                     postId,
                     getPostComments,
                     onSubmit,
                     comments,
                     userId,
                     deletePostComment,
                     postUserId,
                     handleClick,
                     commentsNumber,
                     likesNumber,
                     user,
                     likePost,
                     ihadLike,
                     imageProfile,
                     type,
                     linkifiedText,
                     findYoutubeUrlString
                   }) => {
  const [videoData, setVideoData] = useState(null);
  const { t } = useTranslation(COMPONENT_POSTCARD);
  const history = useHistory();
  const { accessCode } = useUserData();
  const [expanded, setExpanded] = React.useState(false);
  // const userNameFirstLetter = useMemo(() => (type === 'personal' ? userData?.username?.[0]?.toUpperCase() : user?.firstname?.[0]?.toUpperCase() + user?.lastname?.[0]?.toUpperCase()), [userData, user])
  const handleExpandClick = (postId) => {
    getPostComments({ postId, accessCode });
    setExpanded(!expanded);
  };

  const urlForLink = type === "company" ?
    getRouteUrl(ROUTE_PAGE_COMPANY_CENTER, { id: user.companyId })
    : userId !== user.id
      ? getRouteUrl(ROUTE_PAGE_FRIEND, { username: user.username, id: user.id })
      : getRouteUrl(ROUTE_PAGE_PERSONAL, { username: user.username });

  const fetchVideoDetails = async () => {
    const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${findYoutubeUrlString}&key=${API_KEY}`,
      );
      setVideoData(response.data.items[0].snippet);
    } catch (error) {
      console.error("Error fetching video details:", error);
    }
  };
  useEffect(() => {

    if (findYoutubeUrlString) {
      fetchVideoDetails();
    }
  }, [findYoutubeUrlString]);

  return (
    <Card>
      <CardHeader
        avatar={
          (imageProfile) &&
          <Avatar style={{ cursor: "pointer" }} onClick={() => history.push(urlForLink)} sx={{ width: 40, height: 40 }}
                  src={imageProfile}></Avatar>

        }
        action={
          postUserId === userId && <Box>
            <IconButton
              onClick={(e) => handleClick({ postId, e })}
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>}
        title={<Box style={{ cursor: "pointer" }} onClick={() => history.push(urlForLink)}>
          {user?.firstname + " " + user?.lastname}
        </Box>
        }
        subheader={<Box fontSize={"0.533333rem"}>{date}</Box>}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary" style={{ wordWrap: "break-word" }}>
          <div dangerouslySetInnerHTML={{ __html: linkifiedText }} />
        </Typography>
      </CardContent>
      <>
        {videoData && findYoutubeUrlString && !assets && !asset && (
          <div className="youtube-preview">
            <a href={`https://www.youtube.com/watch?v=${findYoutubeUrlString}`} target="_blank"
               rel="noopener noreferrer">
              <img className="youtube-thumbnail" src={videoData.thumbnails.high.url} alt={videoData.title} />
            </a>
            <div className="youtube-info">
              <a href={`https://www.youtube.com/watch?v=${findYoutubeUrlString}`} target="_blank"
                 rel="noopener noreferrer"
                 className="youtube-link">
                <div className="youtube-title">{videoData.title}</div>
              </a>
              <div className="youtube-description">{videoData.description}</div>
            </div>
          </div>
        )
        }
        {
          assets &&
          <ImageList>
            {assets.map((item) => {
              if (item?.type.includes("image")) {
                return (
                  <ImageListItem key={item.filename}>
                    <img
                      src={`${item.url}`}
                      alt={item.filename}
                      loading="lazy"
                      style={{ objectFit: "contain" }}
                      onClick={() => setOpenModal(item.url)}
                    />
                  </ImageListItem>
                );
              } else if (item?.type.includes("pdf")) {
                return (<ImageListItem key={item.filename}>
                  <Link href={item.url} target="_blank"
                        underline="hover" sx={{
                    fontSize: "large",
                    color: "#616161",
                    fontFamily: "Open Sans-Regular",
                    border: "1px solid grey",
                    borderRadius: "25px",
                    padding: "5px 5px 5px 10px",
                    margin: "10px",
                  }}>
                    <PictureAsPdf />
                    {item?.filename}
                  </Link>
                </ImageListItem>);
              } else {
                return null;
              }
            })}
          </ImageList>}
      </>
      {asset ? asset?.type.includes("image") ? <CardMedia
          component="img"
          height="194"
          image={asset.url}
          alt="Paella dish"
          onClick={() => setOpenModal(asset.url)}
        /> :
        <Link href={asset.url} target="_blank"
              underline="hover" sx={{
          fontSize: "large",
          color: "#616161",
          fontFamily: "Open Sans-Regular",
          border: "1px solid grey",
          borderRadius: "25px",
          padding: "5px 5px 5px 10px",
          margin: "10px",
        }}>
          <PictureAsPdf />
          {asset?.filename}
        </Link> : null
      }
      <CardActions disableSpacing>
        <Box display="flex" justifyContent="space-between" width={"100%"}>
          <Box>
            <IconButton aria-label="add to favorites" onClick={() => likePost({
              postId: postId,
              accessCode,
            })}>
              <FavoriteIcon style={{ fontSize: "0.8rem", color: ihadLike === "1" ? "red" : "grey" }} />
              <Box fontSize={"1.2rem"}>{likesNumber}</Box>
            </IconButton>
            {/*<IconButton aria-label="share">*/}
            {/*    <ShareIcon/>*/}
            {/*</IconButton>*/}
          </Box>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography sx={{
              "&.MuiTypography-root": {
                color: "#616161",
                fontFamily: "\"Open Sans-Regular\", Helvetica",
                fontSize: "14px",
                fontWeight: "400",
              },
            }}>
              {t("show comments") + " " + commentsNumber}
            </Typography>
            <ExpandMore
              expand={expanded}
              onClick={() => handleExpandClick(postId)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
        </Box>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {comments && comments.map(comment => (
          <CardContent>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box style={{ cursor: "pointer" }} onClick={() => history.push(urlForLink)} display={"flex"}
                   flexDirection={"column"}>
                <Avatar sx={{ width: 40, height: 40 }} src={comment.image_profile}></Avatar>
                <Typography fontSize={12} width={"50px"}>
                  {comment.userfullname}
                </Typography>
              </Box>
              <Typography marginLeft={3} fontSize={12} alignSelf={"center"}>
                {comment.comment}
              </Typography>
              {comment.id_user === userId ?
                <IconButton aria-label="delete comment" onClick={() => deletePostComment({
                  commentId: comment.id_comment,
                  postId: postId,
                  accessCode,
                })}>
                  <DeleteIcon />
                </IconButton> :
                <Box width={"56px"}></Box>
              }
            </Box>
          </CardContent>
        ))}

      </Collapse>
      <Formik onSubmit={onSubmit} initialValues={{}}>
        {props => {
          return (
            <Form>
              <Box className="thinkingInput"
                   style={{ justifyContent: "space-between", margin: "0px 3px 5px 3px" }}
              >
                <Input
                  sx={{ padding: "5px" }}
                  // className="thinking"
                  name={`${postId}_keimeno`}
                  inputtype={"text"}
                  placeholder={t("add comment")}
                  disableUnderline={true}
                  onChange={(e) => {
                    props.setFieldValue(`keimeno`, { value: e.target.value, postId: postId });
                  }}
                  value={props.values.keimeno?.value || ""}
                />
                <IconButton color="primary" onClick={() => {
                  setExpanded(false);
                  props.handleSubmit();
                }}>
                  <SendIcon />
                </IconButton>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};


export default MediaCard;