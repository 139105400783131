// @flow
import React, {useEffect, useState} from 'react';
import FeedLayout from "../layouts/feedLayout/feedLayout";
import {FormikProvider, useFormik} from "formik";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../locales/pages/namespaces";
import * as yup from "yup";
import useUserData from "../../hooks/user/useUserData";
import './editUserDetails.css'
import { Button, Checkbox, FormControlLabel, FormGroup, IconButton, TextField, Typography } from "@mui/material";
import useUserAction from "../../hooks/user/useUserAction";
import {enqueueSnackbar} from "notistack";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME, ROUTE_PAGE_JOBS, ROUTE_PAGE_LOGIN} from "../../routers/routes";
import { Check, Close, Email } from "@mui/icons-material";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import PasswordInput from "../passwordInput/passwordInput";
import ChangePassword from "../changePassword/changePassword";
import Link from "@mui/material/Link";
import PersonalDetailsEdit from "../personalDetailsEdit/personalDetailsEdit";
import { useMutation, useQuery } from "react-query";
import {
	deleteAccountReq,
	getUserCategoryMoodle,
} from "../../api/requests";
import ContentLoader from "../contentLoader/contentLoader";
import UserDetailsMoodle from "../../assets/userDetailsMoodle/userDetailsMoodle";
import OstriaModal from "../ostriaModal/ostriaModal";
import { GENERIC } from "../../locales/components/namespaces";


const EditUserDetails = (props: Props) => {
	const {t} = useTranslation(PAGE_PERSONAL);
	const { t:g } = useTranslation(GENERIC);
	const {postUserDetails, setUserMessage,userLogout} = useUserAction()
	const {data,accessCode,message} = useUserData();

	const [changePassword,setChangePassword] = useState(false)
	const [changeDetails,setChangeDetails] = useState(false)
	const [deleteAccount,setDeleteAccount] = useState(false)

	const { data: userCategories, isLoading,refetch } = useQuery(
		["getUserCategoryMoodle", accessCode],
		getUserCategoryMoodle,
	);

	const { mutate: deleteAccountAction } = useMutation({
		mutationFn: deleteAccountReq,
		onSuccess: (data, variables, context) => {
			userLogout()
		},
	});

	const validationSchema = yup.object({
		email: yup
		.string().required(),
		firstName: yup
		.string().required(),
		lastName: yup
		.string().required(),
		mobile: yup
		.string().required(),
		date_birth: yup
		.date().required(),
	})
	const onSubmit = (values) => {
		postUserDetails(values)

	}
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant,
					autoHideDuration: 10000
					
				}
			)
			setUserMessage(null)
			if(message === "data_saved"){
				// window.location.href = getRouteUrl(ROUTE_PAGE_HOME)
			}
		}
	}, [message, setUserMessage, t])
	const formik = useFormik({
		initialValues: {
			firstName: data.firstName,
			lastName: data.lastName,
			mobile: data.mobile,
			email: data.email,
			date_birth: dayjs(data.date_birth),
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
		
	})

	return (
		<FeedLayout>
			<FormikProvider value={formik} onSubmit={formik.handleSubmit}>
				<Box component="form"
					 onSubmit={formik.handleSubmit}
					 className="editUserDetails">
					<Box className={"title"}>
						<Typography>
							{t('edit details')}
						</Typography>
					</Box>
					<Box className="formFields">
						<TextField
							id={'firstName'}
							value={formik.values.firstName}
							error={formik?.errors?.firstName}
							name={'firstName'}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							label={t('firstName')}
							size={'small'}
							inputProps={{maxLength: 64}}
							variant="outlined"
							sx={{
								marginTop: '10px',
								marginBottom:'15px',
								'& .MuiOutlinedInput-root': {
									':focus-within': {border: '0.0px solid #ffffff !important'}
								},
								'& .MuiFormLabel-root': {
									color: '#616161',
									fontSize: '15px',
									fontWeight: 400,
								},
							}}
						/>
						<TextField
							id={'lastName'}
							value={formik.values.lastName}
							error={formik?.errors?.lastName}
							name={'lastName'}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							label={t('lastName')}
							size={'small'}
							inputProps={{maxLength: 64}}
							variant="outlined"
							sx={{
								marginBottom:'15px',
								'& .MuiOutlinedInput-root': {
									':focus-within': {border: '0.0px solid #ffffff !important'}
								},
								'& .MuiFormLabel-root': {
									color: '#616161',
									fontSize: '15px',
									fontWeight: 400,
								},
							}}
						/>
						<TextField
							id={'email'}
							value={formik.values.email}
							error={formik?.errors?.email}
							name={'email'}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							label={t('email')}
							size={'small'}
							inputProps={{maxLength: 64}}
							variant="outlined"
							sx={{
								marginBottom:'15px',
								'& .MuiOutlinedInput-root': {
									':focus-within': {border: '0.0px solid #ffffff !important'}
								},
								'& .MuiFormLabel-root': {
									color: '#616161',
									fontSize: '15px',
									fontWeight: 400,
								},
							}}
						/>
						<TextField
							id={'mobile'}
							value={formik.values.mobile}
							error={formik?.errors?.mobile}
							name={'mobile'}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							label={t('mobile')}
							size={'small'}
							inputProps={{maxLength: 64}}
							variant="outlined"
							sx={{
								marginBottom:'15px',
								'& .MuiOutlinedInput-root': {
									':focus-within': {border: '0.0px solid #ffffff !important'}
								},
								'& .MuiFormLabel-root': {
									color: '#616161',
									fontSize: '15px',
									fontWeight: 400,
								},
							}}
						/>
						
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								value={formik.values.date_birth || ''}   //dayValue
								label={t('date_birth')}
								format="YYYY-MM-DD"
								size={'small'}
								onChange={(newValue) => {
									formik.setFieldValue('date_birth',newValue)
								}}
								sx={{marginBottom: '10px'}}
								renderInput={(params) =>
									<TextField
										{...params}
										error={formik?.errors?.date_birth}
										placeholder={t('date_birth')}
										variant={'outlined'}
									/>}
								slotProps={{
									textField: {variant: 'outlined',size: 'small', placeholder: t('date_birth')}
								}}
							/>
						</LocalizationProvider>
				
					
					</Box>
					<Box className={"formButtons"}>
						<Button
							variant="outlined"
							type="submit"
							sx={{
								marginRight:'20px',
								backgroundColor:'#27293d',
								borderRadius:'4px',
								color: '#ffffff',
								'&:hover': {
									backgroundColor:'#27293d',
								},
								width: '35%',
							}}
						>
							{t('save')}
						</Button>
						<Button
							onClick={()=>window.location.href = getRouteUrl(ROUTE_PAGE_HOME)}
							sx={{
								borderRadius:'4px',
								width: '35%',
							}}
							variant="outlined"
						>
							{t('cancel')}
						</Button>
					</Box>
				</Box>
			</FormikProvider>
			{isLoading  ?
				<ContentLoader loading={isLoading}></ContentLoader>
				: <UserDetailsMoodle userCategories={userCategories} refetch={refetch} />}
			<br></br>
			<Box sx={{width:'100%',display:'flex',justifyContent:'space-between'}}>
				<Box>
				{!changePassword &&
					<Link   component="button" onClick={()=>setChangePassword(true)} underline="hover" sx={{fontSize:'medium'}}>{t('Change password')}</Link>
				}
				</Box>
				<Box>
					{!changeDetails &&
						<Link   component="button" onClick={()=>setChangeDetails(true)} underline="hover" sx={{fontSize:'medium'}}>{t('personal details')}</Link>
					}
				</Box>
				<Box>
					<Link   component="button" onClick={()=>setDeleteAccount(true)} underline="hover" sx={{fontSize:'medium'}}>{t('Delete Account')}</Link>
				</Box>

			</Box>
			
			<br></br>
			{changePassword &&
			<ChangePassword setChangePassword = {()=>setChangePassword(false)}/>
			}
			<br></br>
			{changeDetails &&
			<PersonalDetailsEdit setChangeDetails = {()=> setChangeDetails(false)}/>
			}
			<br></br>
			<OstriaModal
				open={deleteAccount}
				onClose={()=>setDeleteAccount(false)}
			>
				<Box
					sx={{
						height: "auto",
						padding:'16px',
						margin: "auto",
					}}
				>
					<Typography
						sx={{
							color: "#050505",
							fontFamily: '"Segoe UI-Bold", Helvetica',
							fontSize: { xs: "15px", md: "21px" },
							fontWeight: "700",
							marginBottom: { xs: "5px", md: "10px" },
							textAlign:'center',
						}}
					>
						{g('Are you sure?')}
					</Typography>
					<Box className="reportButtons">
						<IconButton onClick={()=>deleteAccountAction({email:data.email})}>
							<Check color="success"/>
							{g('yes')}
						</IconButton>
						<IconButton onClick={()=>setDeleteAccount(false)}>
							<Close sx={{color:'red'}}/>
							{g('no')}
						</IconButton>
					</Box>
				</Box>
			</OstriaModal>
		</FeedLayout>
		
	);
};

export default EditUserDetails