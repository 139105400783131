// @flow
import React, { useState, useEffect } from "react";
import Images from "../../assets";
import {
  Button,
  IconButton,
  ListItemButton,
  Modal,
  Typography,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import {
  AccountCircle,
  MailOutline,
  People,
  PersonAddAlt1Outlined,
  PhotoCamera,
  StarRate,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { PAGE_PERSONAL } from "../../locales/pages/namespaces";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ReportIcon from "@mui/icons-material/Report";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import CreateDivision from "../userMoreMenu/components/createDivision";
import Report from "../userMoreMenu/components/report";
import axios from "axios";
import useUserData from "../../hooks/user/useUserData";
import { useQuery } from "react-query";
import { getUserGamification } from "../../api/requests";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import {
  ROUTE_PAGE_CHAT_FRIEND,
  ROUTE_PAGE_GROUPS_CREATE,
} from "../../routers/routes";
import { useHistory } from "react-router";
import OstriaModal from "../ostriaModal/ostriaModal";
import useMediaQuery from "@mui/material/useMediaQuery";

type Props = {};

const FriendProfileHeader = (props: Props) => {
  const { t } = useTranslation(PAGE_PERSONAL);
  //cover - popover
  const data = props.data;
  const history = useHistory();

  const { accessCode, userId } = useUserData();
  const [sendRequest, setSendRequest] = useState(
    data["isfriend"] === "false" ? false : true
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [openReportPopUp, setOpenReportPopUp] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [coverAnchorEl, setCoverAnchorEl] = useState(null);
  const coverOpen = Boolean(coverAnchorEl);
  const handleCoverClick = (event) => {
    setCoverAnchorEl(event.currentTarget);
  };
  const handleCoverClose = () => {
    setCoverAnchorEl(null);
  };


  //image profile popove
  const [imageAnchorEl, setImageAnchorEl] = React.useState(null);
  const imageOpen = Boolean(imageAnchorEl);
  const handleImageClick = (event) => {
    setImageAnchorEl(event.currentTarget);
  };
  const handleImageClose = () => {
    setImageAnchorEl(null);
  };


  //cover image full screen
  const [showFullCoverScreen, setShowFullCoverScreen] = useState(false);

  const handleShowCoverClick = () => {
    setShowFullCoverScreen(true);
  };

  const handleCloseCoverClick = () => {
    setShowFullCoverScreen(false);
  };

  //image profile full screen
  const [showFullImageScreen, setShowFullImageScreen] = useState(false);

  const handleShowImageClick = () => {
    setShowFullImageScreen(true);
  };

  const handleCloseImageClick = () => {
    setShowFullImageScreen(false);
  };

  const handleRequestSend = () => {
    axios({
      url: "https://dev.ostrialinked.gr/api/api.php?q=122a&scode=" + accessCode,
      method: "post",
      data: { idu: data["id_user"] },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        setSendRequest(true);
      })
      .catch((error) => {});
  };
  const handleRemoveRequest = () => {
    axios({
      url: "https://dev.ostrialinked.gr/api/api.php?q=122b&scode=" + accessCode,
      method: "post",
      data: { idu: data["id_user"] },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        setSendRequest(false);
      })
      .catch((error) => {});
  };
  const matches = useMediaQuery('(max-width:600px)');
  return (
    <Box className="profileImages">
      {/*<Box>*/}
      <OstriaModal open={showFullCoverScreen} onClose={handleCloseCoverClick}>
        <img
          style={{objectFit:'cover',width:'100%',height:'100%'}}
          // src={Images.defaultCover}
          src={data["banner"] ? data["banner"] : Images.defaultCover}
          alt="Full Screen"
        />
      </OstriaModal>
      <OstriaModal open={showFullImageScreen} onClose={handleCloseImageClick}>
        <img
          style={{objectFit:'cover',width:'100%',height:'100%'}}
          // src={Images.defaultCover}
          src={ data["imagefile"] ? data["imagefile"] : Images.defaultProfile}
          alt="Full Screen"
        />
      </OstriaModal>
      <div>
        <Button
          className="userCover_png"
          aria-describedby={'simple-popover-cover-profile'}
          onClick={handleCoverClick}
        >
          <img
            className="userCover_png"
            alt="usercover_png"
            // src={(userCoverImg.photobanner)? userCoverImg.photobanner : Images.defaultCover }
            src={data["banner"] ? data["banner"] : Images.defaultCover}
          />
        </Button>
        <Popover
          id={'simple-popover-cover-profile'}
          open={coverAnchorEl}
          anchorEl={coverAnchorEl}
          onClose={handleCoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Box sx={{ display: "grid" }}>
            <Tooltip title={t("show cover profile")}>
              <Button
                sx={{ justifyContent: "start" }}
                onClick={() => {
                  console.log("show image profile");
                  setCoverAnchorEl(null); //gia na kleinei to popover, otan anoigei to quickview
                  handleShowCoverClick();
                }}
              >
                <AccountCircle />
                {t("show cover profile")}
              </Button>
            </Tooltip>
            {/*<Tooltip title={t('change image profile')}>*/}
            {/*	<Button sx={{justifyContent: 'start'}} onClick={() => {*/}
            {/*		console.log('change cover profile')*/}
            {/*		setCoverAnchorEl(null) //gia na kleinei to popover, otan anoigei to quickview*/}
            {/*		uploadModal()*/}
            {/*		setImageType('cover')*/}
            {/*	}}>*/}
            {/*		<PhotoCamera/>*/}
            {/*		{t('change cover profile')}*/}
            {/*	</Button>*/}
            {/*</Tooltip>*/}
          </Box>
        </Popover>
      </div>
      {/*<div style={{ height: "200px" }}>*/}
      {/*  <Button*/}
      {/*    aria-describedby={'simple-popover-cover-profile'}*/}
      {/*    className="imageButton nohover"*/}
      {/*    onClick={handleImageClick}*/}
      {/*  >*/}
      {/*    <img*/}
      {/*      className="userImage_png"*/}
      {/*      alt="Avatar"*/}
      {/*      // src={(userProfileImg.photoprofile)? userProfileImg.photoprofile : Images.defaultProfile  }*/}
      {/*      src={data["imagefile"] ? data["imagefile"] : Images.defaultProfile}*/}
      {/*    />*/}
      {/*  </Button>*/}
      {/*  <Popover*/}
      {/*    id={'simple-popover-cover-profile'}*/}
      {/*    open={imageAnchorEl}*/}
      {/*    anchorEl={imageAnchorEl}*/}
      {/*    onClose={handleImageClose}*/}
      {/*    anchorOrigin={{*/}
      {/*      vertical: "center",*/}
      {/*      horizontal: "left",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Box sx={{ display: "grid" }}>*/}
      {/*      <Tooltip title={t("show image profile")}>*/}
      {/*        <Button*/}
      {/*          sx={{ justifyContent: "start" }}*/}
      {/*          onClick={() => {*/}
      {/*            console.log("show image profile");*/}
      {/*            setImageAnchorEl(null); //gia na kleinei to popover, otan anoigei to quickview*/}
      {/*            handleShowImageClick();*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <AccountCircle />*/}
      {/*          {t("show image profile")}*/}
      {/*        </Button>*/}
      {/*      </Tooltip>*/}
      {/*    </Box>*/}
      {/*  </Popover>*/}
      {/*</div>*/}
      {/*<Box className="userDetailsContainer">*/}
      {/*  <Box className="userDetails">*/}
      {/*    <Box className="userDetailsLabels">*/}
      {/*      <label className="userDetailsLabel">*/}
      {/*        {data["firstname"]} {data["lastname"]}*/}
      {/*      </label>*/}
      {/*    </Box>*/}
      {/*    <Box className="userDetailsButtons">*/}
      {/*      {sendRequest === false ? (*/}
      {/*        <IconButton*/}
      {/*          className="addUser"*/}
      {/*          size="small"*/}
      {/*          onClick={() => handleRequestSend()}*/}
      {/*          sx={{*/}
      {/*            color: "white",*/}
      {/*            backgroundColor: "black",*/}
      {/*            borderRadius: "5px",*/}
      {/*            "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },*/}
      {/*            height: "28px",*/}
      {/*            paddingLeft: "5px",*/}
      {/*            marginRight: "5px",*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <PersonAddAlt1Outlined />*/}
      {/*          /!*<Avatar sx={{width:'15px', height:'15px'}} src={Images.add_user}/>*!/*/}
      {/*          <Box*/}
      {/*            sx={{*/}
      {/*              marginLeft: "3%",*/}
      {/*              fontSize: "smaller",*/}
      {/*              fontFamily: "Open Sans-Regular, Helvetica",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            {t("add")}*/}
      {/*          </Box>*/}
      {/*        </IconButton>*/}
      {/*      ) : (*/}
      {/*        <IconButton*/}
      {/*          className="addUser"*/}
      {/*          size="small"*/}
      {/*          onClick={() => handleRemoveRequest()}*/}
      {/*          sx={{*/}
      {/*            color: "white",*/}
      {/*            backgroundColor: "black",*/}
      {/*            borderRadius: "5px",*/}
      {/*            "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },*/}
      {/*            height: "28px",*/}
      {/*            paddingLeft: "5px",*/}
      {/*            marginRight: "5px",*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <People />*/}
      {/*          /!*<Avatar sx={{width:'15px', height:'15px'}} src={Images.add_user}/>*!/*/}
      {/*          <Box*/}
      {/*            sx={{*/}
      {/*              marginLeft: "5px",*/}
      {/*              fontSize: "smaller",*/}
      {/*              fontFamily: "Open Sans-Regular, Helvetica",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            {t("delete friend")}*/}
      {/*          </Box>*/}
      {/*        </IconButton>*/}
      {/*      )}*/}
      {/*      <IconButton*/}
      {/*        onClick={() => {*/}
      {/*          history.push(*/}
      {/*            getRouteUrl(ROUTE_PAGE_CHAT_FRIEND, {*/}
      {/*              id: parseInt(props.data["id_user"]),*/}
      {/*            })*/}
      {/*          );*/}
      {/*        }}*/}
      {/*        className="message"*/}
      {/*        size="small"*/}
      {/*        sx={{*/}
      {/*          color: "black",*/}
      {/*          // backgroundColor: "black",*/}
      {/*          border: "1px black solid",*/}
      {/*          borderRadius: "5px",*/}
      {/*          "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },*/}
      {/*          height: "28px",*/}
      {/*          paddingLeft: "5px",*/}
      {/*          marginRight: "5px",*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <MailOutline />*/}
      {/*        /!*<Avatar sx={{width:'15px', height:'15px'}} src={Images.message}/>*!/*/}
      {/*        <Box*/}
      {/*          sx={{*/}
      {/*            marginLeft: "3%",*/}
      {/*            fontSize: "smaller",*/}
      {/*            fontFamily: "Open Sans-Regular, Helvetica",*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          {t("message")}*/}
      {/*        </Box>*/}
      {/*      </IconButton>*/}
      {/*      <IconButton*/}
      {/*        className="more"*/}
      {/*        size="small"*/}
      {/*        onClick={handleClick}*/}
      {/*        sx={{*/}
      {/*          // color: "black",*/}
      {/*          // backgroundColor: "black",*/}
      {/*          border: "1px black solid",*/}
      {/*          borderRadius: "5px",*/}
      {/*          "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },*/}
      {/*          height: "28px",*/}
      {/*          paddingLeft: "5px",*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <Avatar*/}
      {/*          sx={{ width: "15px", height: "15px" }}*/}
      {/*          src={Images.more}*/}
      {/*        />*/}
      {/*      </IconButton>*/}
      {/*      <Menu*/}
      {/*        id={'friend-profile'}*/}
      {/*        anchorEl={anchorEl}*/}
      {/*        open={open}*/}
      {/*        onClose={handleClose}*/}
      {/*        transformOrigin={{ horizontal: "right", vertical: "top" }}*/}
      {/*        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}*/}
      {/*      >*/}
      {/*        <ListItemButton onClick={() => setOpenReportPopUp(true)}>*/}
      {/*          <ListItemIcon>*/}
      {/*            <ReportIcon />*/}
      {/*          </ListItemIcon>*/}
      {/*          <ListItemText primary={"Report"} />*/}
      {/*        </ListItemButton>*/}

      {/*        <ListItemButton onClick={() => setOpenPopUp(true)}>*/}
      {/*          <ListItemIcon>*/}
      {/*            <StarRate />*/}
      {/*          </ListItemIcon>*/}
      {/*          <ListItemText primary={"Διάκριση"} />*/}
      {/*        </ListItemButton>*/}
      {/*      </Menu>*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*  <Box className="headerText">*/}
      {/*    <Box className="htmlText">*/}
      {/*      <Typography*/}
      {/*        sx={{ fontSize: "small" }}*/}
      {/*        component={"div"}*/}
      {/*        dangerouslySetInnerHTML={{ __html: data["description"] }}*/}
      {/*      />*/}
      {/*    </Box>*/}
      {/*    <Box className="headerPoints">*/}
      {/*      <img className="star_png" alt="star_png" src={Images.star} />*/}
      {/*      {t("participation points")}: {data["pontoi"]}*/}
      {/*    </Box>*/}
      {/*  </Box>*/}

      {/*  /!*<Box className="extraDescriptionEditor">*!/*/}
      {/*  /!*	<Typography sx={{fontSize: 'small'}}*!/*/}
      {/*  /!*				component={"div"}*!/*/}
      {/*  /!*				dangerouslySetInnerHTML={{__html: htmlText2}}*!/*/}
      {/*  /!*	/>*!/*/}

      {/*  /!*</Box>*!/*/}
      {/*</Box>*/}
      <Box display={'flex'} justifyContent={matches ? 'center' :'space-between'} flexDirection={matches ? 'column' : null} height={matches ? 120 : 70}>
        <Box width={"100%"} display={'flex'} height={matches ? 80 : null} >
          <img id={'profile'} className="userImage_png"  alt="Avatar" onClick={handleCoverClick}
               src={data["imagefile"] ? data["imagefile"] : Images.defaultProfile}/>
          <Box width={"100%"}>
            <Box className="userDetails">
              <Box className="userDetailsLabels">
                <label className="userDetailsLabel">{data["firstname"]} {data["lastname"]}</label>
              </Box>
              <Box className="userDetailsButtons">
                {sendRequest === false ? (
                  <IconButton
                    className="addUser"
                    size="small"
                    onClick={() => handleRequestSend()}
                    sx={{
                      color: "white",
                      backgroundColor: "black",
                      borderRadius: "5px",
                      "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },
                      height: "28px",
                      paddingLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <PersonAddAlt1Outlined />
                    {/*<Avatar sx={{width:'15px', height:'15px'}} src={Images.add_user}/>*/}
                    <Box
                      sx={{
                        marginLeft: "3%",
                        fontSize: "smaller",
                        fontFamily: "Open Sans-Regular, Helvetica",
                      }}
                    >
                      {t("add")}
                    </Box>
                  </IconButton>
                ) : (
                  <IconButton
                    className="addUser"
                    size="small"
                    onClick={() => handleRemoveRequest()}
                    sx={{
                      color: "white",
                      backgroundColor: "black",
                      borderRadius: "5px",
                      "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },
                      height: "28px",
                      paddingLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <People />
                    {/*<Avatar sx={{width:'15px', height:'15px'}} src={Images.add_user}/>*/}
                    <Box
                      sx={{
                        marginLeft: "5px",
                        fontSize: "smaller",
                        fontFamily: "Open Sans-Regular, Helvetica",
                      }}
                    >
                      {t("delete friend")}
                    </Box>
                  </IconButton>
                )}
                <IconButton
                  onClick={() => {
                    history.push(
                      getRouteUrl(ROUTE_PAGE_CHAT_FRIEND, {
                        id: parseInt(props.data["id_user"]),
                      })
                    );
                  }}
                  className="message"
                  size="small"
                  sx={{
                    color: "black",
                    // backgroundColor: "black",
                    border: "1px black solid",
                    borderRadius: "5px",
                    "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },
                    height: "28px",
                    paddingLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <MailOutline />
                  {/*<Avatar sx={{width:'15px', height:'15px'}} src={Images.message}/>*/}
                  <Box
                    sx={{
                      marginLeft: "3%",
                      fontSize: "smaller",
                      fontFamily: "Open Sans-Regular, Helvetica",
                    }}
                  >
                    {t("message")}
                  </Box>
                </IconButton>
                <IconButton
                  className="more"
                  size="small"
                  onClick={handleClick}
                  sx={{
                    // color: "black",
                    // backgroundColor: "black",
                    border: "1px black solid",
                    borderRadius: "5px",
                    "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },
                    height: "28px",
                    paddingLeft: "5px",
                  }}
                >
                  <Avatar
                    sx={{ width: "15px", height: "15px" }}
                    src={Images.more}
                  />
                </IconButton>
                <Menu
                  id={'friend-profile'}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <ListItemButton onClick={() => setOpenReportPopUp(true)}>
                    <ListItemIcon>
                      <ReportIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Report"} />
                  </ListItemButton>

                  <ListItemButton onClick={() => setOpenPopUp(true)}>
                    <ListItemIcon>
                      <StarRate />
                    </ListItemIcon>
                    <ListItemText primary={"Διάκριση"} />
                  </ListItemButton>
                </Menu>
              </Box>
            </Box>
            <Box className="headerText">
              <Box className="htmlText">
                <Typography
                  sx={{ fontSize: "small" }}
                  component={"div"}
                  dangerouslySetInnerHTML={{ __html: data["description"] }}
                />
              </Box>
              <Box className="headerPoints">
                <img className="star_png" alt="star_png" src={Images.star} />
                {t("participation points")}: {data["pontoi"]}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <OstriaModal open={openReportPopUp} onClose={() => setOpenReportPopUp(false)}>
          <Report onClose={() => setOpenReportPopUp(false)} />
      </OstriaModal>
      <OstriaModal open={openPopUp} onClose={() => setOpenPopUp(false)}>
          <CreateDivision onClose={() => setOpenPopUp(false)} id={props.data.id_user} />
      </OstriaModal>
    </Box>
  );
};

export default FriendProfileHeader;
