import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.PAGE_LOGIN]: {
		el: {
			'Connection' : 'Σύνδεση',
			'Enter your email or username' : 'Εισάγετε το email ή το όνομα χρήστη',
			'Password' : 'Κωδικός',
			'Remember me' : 'Να με θυμάσαι',
			'Login' : 'Είσοδος',
			'Forget password' : 'Ξέχασα τον κωδικό',
			'Login with Google' : 'Συνδεθείτε με Google',
			'Login with Facebook' : 'Συνδεθείτε με Facebook',
			'Want to get started now?' : 'Θέλετε να ξεκινήσετε τώρα;',
			'Create an account' : 'Δημιουργήστε λογαριασμό',
			'required' : 'Απαιτείται',
			'no_confirm' : 'Δεν υπάρχει επιβεβαίωση ',
			'user_not_found' : 'Δεν βρέθηκε ο χρήστης',
			'data_saved' : 'Αποθηκεύτηκε',
		},
		en: {}
	},
	[Namespaces.PAGE_HOME]: {
		el: {
			'required' : 'Απαιτείται',
			'search' : 'Αναζήτηση',
			'home' : 'Αρχική',
			'personal profile' : 'Προσωπικό προφίλ',
			'education platform' : 'Πλατφόρμα εκπαίδευσης',
			'messages' : 'Μηνύματα',
			'notification' : 'Ειδοποιήσεις',
			'building pages' : 'Σελίδες επιχειρήσεων',
			'groups' : 'Ομάδες',
			'finding a job': 'Εύρεση εργασίας',
			'important announcements': 'Σημαντικές Ανακοινώσεις',
			'Last News': 'Τελευταία Νέα',
			'Powered by Google News': 'Powered by Google News',
			'notifications settings': 'Ρυθμίσεις ειδοποιήσεων',
			'ostria info' : 'Πληροφορίες ostria',
			'help' : 'Βοήθεια',
			'faq' : 'FAQ',
			'add' : 'Δημιουργία',
			'group' : 'Ομάδας',
			'company' : 'Επιχείρησης',
			'create a post' : 'Δημιουργία Ανάρτησης',
			'global' : 'Δημόσιο',
			'what are you thinking?' : 'Τι σκέφτεστε;',
			'sharing' : 'Κοινοποίηση',
			'edit details' : 'Επεξεργασία Στοιχείων',
			'privacy': 'Ιδιωτικό',
			'data_saved' : 'Αποθηκεύτηκε',
			'delete' : 'Διαγραφή',
		},
		en: {}
	},
	[Namespaces.PAGE_RESET_PASSWORD]: {
		el: {
			'Account Recovery' : 'Ανάκτηση Λογαριασμού',
			'Enter your account email address and a password recovery email will be sent to you' : 'Συμπληρώστε την διεύθυνση email του λογαριασμού σας και θα σας σταλεί email ανάκτησης του κωδικού',
			'Email Address' : 'Διεύθυνση email',
			'I want a new password' : 'Θέλω νέο κωδικό πρόσβασης',
			'Create an account' : 'Δημιουργήστε λογαριασμό',
			'Login to the system' : 'Είσοδος στο σύστημα',
			'required' : 'Απαιτείται',
			'Password' : 'Κωδικός',
			'data_saved' : 'Αποθηκεύτηκε',
		},
		en: {}
	},
	[Namespaces.PAGE_REGISTER]: {
		el: {
			'required': 'Απαιτείται',
			'Connect': 'Συνδεθείτε',
			'Create an account': 'Δημιουργία Λογαριασμού',
			'Your Name': 'Το όνομά σας',
			'Last Name': 'Επώνυμο',
			'User Name': 'Όνομα Χρήστη',
			'Date Birth' : 'Ημ. Γέννησης',
			'Mobile Phone' : 'Κινητό Τηλ',
			'Email Address' : 'Διεύθυνση email',
			'Password' : 'Κωδικός',
			'I have read and agree to the terms of use' :'Έχω διαβάσει και συμφωνώ με τους όρους χρήσης.',
			'Enter a valid email': 'Εισάγετε ένα έγκυρο e-mail',
			'Enter a valid date': 'Εισάγετε μία έγκυρη ημερομηνία',
			'user_register_success' : 'Ο χρήστης δημιουργήθηκε επιτυχώς. Έχει σταλεί e-mail επιβεβαίωσης στον ηλεκτρονικό λογαριασμό που δηλώσατε κατά την εγγραφή σας. Αν δεν βρίσκετε το email παρακαλώ ελέγξετε και τον φάκελο Junk ή Spam.',
			'user_register_failed' : 'Η δημιουργία χρήστη, απέτυχε',
			'You need to accept the terms and conditions': 'Πρέπει να αποδεχτείτε τους όρους χρήσης',
			'username_or_email_exist' : 'To username ή το email υπάρχει ήδη',
			'data_saved' : 'Αποθηκεύτηκε',
		},
		en: {
			'required': 'Required',
		}
	},
	[Namespaces.PAGE_PERSONAL]: {
		el: {
			'delete' : 'Διαγραφή',
			'required' : 'Απαιτείται',
			'add' : 'Προσθήκη',
			'message' : 'Μήνυμα',
			'participation points' : 'Πόντοι συμμετοχής',
			'CENTER' : 'ΚΕΝΤΡΙΚΟ',
			'PERSONAL DETAILS' : 'ΠΡΟΣΩΠΙΚΑ ΣΤΟΙΧΕΙΑ',
			'CV' : 'ΒΙΟΓΡΑΦΙΚΟ',
			'FRIENDS' : 'ΦΙΛΟΙ',
			'GROUPS' : 'ΟΜΑΔΕΣ',
			'FILES' : 'ΑΡΧΕΙΑ',
			'RECOMMENDATIONS' : 'ΔΙΑΚΡΙΣΕΙΣ',
			'general details' : 'Βασικές Πληροφορίες',
			'recommendations' : 'Διακρίσεις',
			'all' : 'Όλα',
			'show all recommendations' : 'Δείτε όλες τις διακρίσεις',
			'search for other jobs...' : 'Αναζητήστε άλλες θέσεις εργασίας...',
			'all them' : 'Όλοι',
			'files' : 'Αρχεία',
			'friends' : 'Φίλοι',
			'cv': 'Βιογραφικό',
			'education' : 'Εκπαίδευση & Πιστοποιήσεις',
			'work experience' : 'Προϋπηρεσία',
			'other information' : 'Άλλες πληροφορίες',
			'groups' : 'Ομάδες',
			'You are member' : 'Είστε μέλος',
			'Become a member' : 'Γίνετε μέλος',
			'create a new group' : 'Δημιουργία νέας ομάδας',
			'cancel request' : 'Ακύρωση αιτήματος',
			'add recomendation' : 'Προσθήκη Διάκρισης',
			'share' : 'Κοινοποίηση',
			'Would you like to block this user?' : 'Θα θέλατε να μπλοκάρετε αυτόν τον χρήστη;',
			'yes' : 'Ναι',
			'no' : 'Όχι',
			'edit' : 'Επεξεργασία',
			'cancel' : 'Ακύρωση',
			'country' : 'Χώρα',
			'city' : 'Πόλη',
			'family_state' : 'Οικ.κατάσταση',
			'work_state' : 'Επαγγ. κατάσταση',
			'address' : 'Διεύθυνση',
			'phone1' : 'Τηλέφωνο 1',
			'phone2' : 'Τηλέφωνο 2',
			'facebook' : 'Facebook',
			'twitter' : 'Twitter',
			'url' : 'Url',
			'save' : 'Αποθήκευση',
			'edit details' : 'Επεξεργασία στοιχείων',
			'firstName' : 'Όνομα',
			'lastName' : 'Επίθετο',
			'email' : 'email',
			'mobile' : 'Κινητό',
			'title' : 'Τίτλος',
			'Provide more information about your cooperation with the user, such as the object of cooperation, the period, etc.' : 'Δώστε περισσότερες πληροφορίες για την συνεργασία σας με τον χρήστη όπως το αντικείμενο συνεργασίας, την περίοδο κ.α.',
			'show image profile' : 'Εμφάνιση εικόνας προφίλ',
			'change image profile' : 'Αλλάξτε εικόνα προφίλ',
			'show cover profile' : 'Εμφάνιση εξωφύλλου προφίλ',
			'change cover profile' : 'Αλλάξτε εξώφυλλο προφίλ',
			'upload' : 'Ανέβασμα',
			'choose an image' : 'Επιλέξτε μία φωτογραφία',
			'data_saved' : 'Αποθηκεύτηκε',
			'date_birth' : 'Ημ/νία Γέννησης',
			'Old Password' : 'Παλιός Κωδικός',
			'New Password' : 'Νέος Κωδικός',
			'Change password' : 'Αλλαγή κωδικού',
			'Delete Account' : 'Διαγραφή λογαριασμού',
			'personal details' : 'Προσωπικά στοιχεία',
			'Change Details' : 'Αλλαγή προσωπικών στοιχείων',
			'Comments' : 'Σχόλια',
			'delete friend' : 'Διαγραφή φίλου',
			'There are not friends' : 'Δεν υπάρχουν φίλοι',
			'uploadCv' : 'Ανέβασμα/τροποποίηση βιογραφικού',
			'File cv' : ' Έγγραφο Βιογραφικού',
			'error' : ' Παρακαλώ προσπαθήστε ξανά αργότερα.',
			'create new recommendation' : 'Προσθήκη διάκρισης',
		},
		en: {
			'required': 'Required',
		},
	},
	[Namespaces.PAGE_COMPANY]: {
		el: {
			'country' : 'Χώρα',
			'title' : 'Τίτλος',
			'afm' : 'ΑΦΜ',
			'address' : 'Διεύθυνση',
			'required' : 'Απαιτείται',
			'city' : 'Πόλη',
			'job' : 'Επάγγελμα',
			'official_title' : 'Επωνυμία επιχείρησης',
			'i like' : 'Μου αρέσει',
			'INITIAL' : 'ΑΡΧΙΚΗ',
			'INFORMATION' : 'ΠΛΗΡΟΦΟΡΙΕΣ',
			'MEMBERS' : 'ΜΕΛΗ',
			'FILES' : 'ΑΡΧΕΙΑ',
			'ADS' : 'ΑΓΓΕΛΙΕΣ',
			'MORE' : 'ΠΕΡΙΣΣΟΤΕΡΑ',
			'information' : 'Πληροφορίες',
			'site' : 'Iστοσελίδα',
			'id_category' : 'Κατηγορία',
			'company size' : 'Company size',
			'center address' : 'Κεντρικά',
			'main address' : 'Κύρια διεύθυνση',
			'founded' : 'Ιδρύθηκε',
			'specialties' : 'Eιδικότητες',
			'locations' : 'Τοποθεσίες',
			'access instructions' : 'Οδηγίες πρόσβασης',
			'members' : 'Μέλη',
			'ADMINISTRATORS' : 'ΔΙΑΧΕΙΡΙΣΤΕΣ',
			'Create New Company' : 'Δημιουργία νέας εταιρείας',
			'ALL MEMBERS' : 'ΟΛΑ ΤΑ ΜΕΛΗ',
			'Dont like' : 'Δεν μου αρέσει',
			'like' : 'Μου αρέσει',
			'companies sites' : 'Σελίδες Επιχειρήσεων',
			'follow' : 'Ακολουθείστε',
			'data_saved' : 'Αποθηκεύτηκε',
			'You are member' : 'Είστε μέλος',
			'Become a member' : 'Γίνετε μέλος',
			'You are the owner' : 'Είστε δημιουργός',
			'Remove member' : 'Αποχώρηση',
			'Delete' : 'Διαγραφή',
			'subtitle': '{{dynamicValue}}',
			'typeProfile': 'Τοπική σελίδα ή επιχείρηση',
			'likes': `Αρέσει σε {{dynamicValue}} χρήστες`,
			'created':'Δημιουργήθηκε στις 13/12/2022',
			'phone': '{{dynamicValue}}',
			'email': '{{dynamicValue}}',
			'siteInfo': '{{dynamicValue}}',
			'worktype': 'Τύπος',
			'date_ins': 'Ημερομηνία',
			'description': 'Περιγραφή',
			'level': 'Επίπεδο',
			'salary': 'Μισθός ανα μήνα',
			'delete': 'Διαγραφή',
			'edit': 'Επεξεργασία',
			'cancel': 'Ακύρωση',
			'deactivate': 'Απενεργοποίηση',
			'activate': 'Ενεργοποίηση',
			'isActive': 'Ενεργή',
			'create': 'Δημιουργία',
			'ads' : 'Αγγελίες',
			'ask ads permission' : 'Αδεια για ανέβασμα αγγελιών',
			'wait ads permission' : 'Αναμονή για επιβεβαίωση',
			'no permission' : 'Δεν έχετε δικαίωμα για ανέβασμα αγγελίων. Παρακαλώ επικοινωνήστε με τον διαχειριστή',
			'you need to become member' : 'Πρέπει να γίνετε μέλος για να δείτε αγγελίες',
			'no ads' : 'Δεν υπάρχουν αγγελίες',
			'logo' : 'Εικόνα προφίλ',
			'banner' : 'Εικόνα cover',
			'List' : 'Λίστα αγγελιών',
			'Form' : 'Δημιουργία/Τροποποίηση Αγγελίας',
			'AdsClaims' : 'Αιτήσεις',
			'show image profile' : 'Εμφάνιση εικόνας εταιρείας',
			'change image profile' : 'Αλλάξτε εικόνα εταιρείας',
			'show cover profile' : 'Εμφάνιση εξωφύλλου εταιρείας',
			'change cover profile' : 'Αλλάξτε εξώφυλλο εταιρείας',
			'CvClaims' : 'Αναζήτηση βιογραφικών',
		},
		en: {
			'required': 'Required',
		}
	},
	[Namespaces.PAGE_GROUP]: {
		el: {
			'required' : 'Απαιτείται',
			'become a member' : 'Γίνετε μέλος',
			'CHATS' : 'ΣΥΖΗΤΗΣΕΙΣ',
			'INFORMATION' : 'ΠΛΗΡΟΦΟΡΙΕΣ',
			'MEMBERS' : 'ΜΕΛΗ',
			'FILES' : 'ΑΡΧΕΙΑ',
			'sponsored' : 'Χορηγούμενο',
			'not sponsored' : 'Mη χορηγούμενο',
			'files' : 'Αρχεία',
			'all' : 'Όλα',
			'information' : 'Πληροφορίες',
			'members' : 'Mέλη',
			'Delete' : 'Διαγραφή',
			'add' : 'Προσθήκη',
			'groups' : 'Ομάδες',
			'You are member' : 'Είστε μέλος',
			'Become a member' : 'Γίνετε μέλος',
			'Remove member' : 'Αποχώρηση',
			'You are the owner' : 'Είστε δημιουργός',
			'Create New Group' : 'Δημιουργία νέας ομάδας',
			'Group Name' : 'Όνομα ομάδας',
			'Group Description' : 'Περιγραφή ομάδας',
			'Image cover' : 'Εικόνα cover',
			'Invite friends' : 'Προσκαλέστε φίλους',
			'private group' : 'Ιδιωτικότητα γκρουπ',
			'create' : 'Δημιουργία',
			'cancel' : 'Ακύρωση',
			'data_saved' : 'Αποθηκεύτηκε',
			'cover' : 'φωτογραφία cover',
			'usersMember' : '{{dynamicValue}} χρήστες είναι μέλη',
			'general details' : 'Βασικές Πληροφορίες',
			'edit' : 'Επεξεργασία',
			'description' : 'Περιγραφή',
			'change image group' : 'Αλλάξτε εικόνα ομάδας',
			'show cover group' : 'Εμφάνιση εξωφύλλου ομάδας',
			'delete' : 'Διαγραφή',
		},
		en: {
			'required': 'Required',
		}
	},
	[Namespaces.PAGE_JOB]: {
		el: {
			'location' : 'Τοποθεσία',
			'search' : 'Αναζήτηση',
			'found' : 'Βρέθηκαν',
			'results' : 'αποτελέσματα',
			'send request' : 'Κάνε αίτηση',
			'employmentType' : 'Τρόπος Απασχόλησης',
			'Salary':'Μισθός',
			'Position Category':'Κατηγορία Θέσης',
			'Experience Level':'Επίπεδο εμπειρίας',
			'announcement' : 'Δημοσίευση',
			'announced' : 'Δημοσιεύτηκε',
			'Cancel request':'Ακύρωση Αιτήματος',
			'full desc position' : 'Πλήρης Περιγραφή Θέσης',
			'Salary(optionally)':'Μισθός(προαιρετικά)',
			'show position' : 'Δες την θέση',
			'all ads' : 'Όλες οι αγγελίες',
			'save' : 'Αποθήκευση',
			'delete' : 'Διαγραφή',
			'Application of interest for a job position' : 'Αίτηση ενδιαφέροντος για θέση εργασίας',
			'Accompanying text' : 'Συνοδευτικό κείμενο',
			'cv' : 'Βιογραφικό',
			'cancel' : 'Άκυρο',
			'data_saved' : 'Αποθηκεύτηκε',
		},
		en: {
		
		}
	},
	[Namespaces.PAGE_SEARCH]: {
		el: {
			'users' : 'Χρήστες',
			'show all results' : 'Δείτε όλα τα αποτελέσματα',
			'companies' : 'Επιχειρήσεις',
			'like it' : 'Μου αρέσει',
			'groups' : 'Ομάδες',
			'ads' : 'Αγγελίες',
			'data_saved' : 'Αποθηκεύτηκε',
		},
		en: {
		
		}
	},
	[Namespaces.PAGE_NOTIFICATIONS]: {
		el: {
			'notifications' : 'Ειδοποιήσεις',
			'settings' : 'Ρυθμίσεις',
			'Mark all as read' : 'Σημειώστε τα όλα ως αναγνωσμένα',
			'There are no new notifications' : 'Δεν υπάρχουν νέες ειδοποιήσεις',
			'data_saved' : 'Αποθηκεύτηκε',
			
		},
		en: {
		
		}
	},
	[Namespaces.PAGE_POLL]: {
		el: {
			'create poll' : 'Δημιουργία Δημοσκόπησης',
			'cancel' : 'Ακύρωση',
			'create' : 'Δημιουργία',
			'question' : 'Ερώτηση',
			'answer1' : 'Απάντηση 1',
			'answer2' : 'Απάντηση 2',
			'answer3' : 'Απάντηση 3',
			'answer4' : 'Απάντηση 4',
			'answer5' : 'Απάντηση 5',
			'Public Voting' : 'Δημόσια Ψηφοφορία',
			'Display all users who voted and the answer they chose.' : 'Να εμφανίζονται όλοι οι χρήστες που ψήφισαν και η απάντηση που επέλεξαν.',
			'Allow Multiple Choices' : 'Επιτρέψτε Πολλαπλές Επιλογές',
			'Allow users to make multiple selections at once.' : 'Επιτρέψτε στους χρήστες να κάνουν συγχρόνως πολλαπλές επιλογές.',
			'Set Expiration Time' : 'Ορίστε Χρόνο Λήξης',
			'The poll will be closed after this date and no one will be able to vote.' : 'Η δημοσκόπηση θα κλείσει μετά από αυτή την ημερομηνία και κανείς δεν θα μπορεί να ψηφίσει.',
			'data_saved' : 'Αποθηκεύτηκε',
			
		},
		en: {
		
		}
	},
}

export default locales