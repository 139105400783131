// @flow
import * as React from "react";
import FeedLayout from "../../components/layouts/feedLayout/feedLayout";
import UserFeeds from "../../components/userFeeds/userFeeds";
import PostingDscr from "../../components/postingDscr/postingDscr";
import { useEffect, useState } from "react";
import useUserData from "../../hooks/user/useUserData";
import useUserAction from "../../hooks/user/useUserAction";
import axios from "axios";
import MediaCard from "../../components/mediaCard/mediaCard";
import { daysPassed, findYoutubeUrl, linkify } from "../../helpers/helpers";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Menu from "@mui/material/Menu";
import { IconButton, ListItemButton, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import { PAGE_HOME } from "../../locales/pages/namespaces";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addPostComment,
  deletePost,
  deletePostComments,
  getFullPost,
  getPostComments,
  getPosts, likePost,
} from "../../api/requests";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_HOME } from "../../routers/routes";
import { useHistory } from "react-router-dom";
import OstriaModal from "../../components/ostriaModal/ostriaModal";
import { GENERIC } from "../../locales/components/namespaces";
import { Check, Close } from "@mui/icons-material";

type Props = {};
export const FullPost = (props: Props) => {
  const id = props.match.params.id;
  const { t } = useTranslation(PAGE_HOME);
  const { t:g } = useTranslation(GENERIC);
  const history = useHistory();
  const { userId, accessCode,isModalOpen: isDeleteModalOpen } = useUserData();
  const { openModal, closeModal } = useUserAction();
  const [isModalOpen, setOpenModal] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const queryClient = useQueryClient();
  const onSubmit = (values, formikHelpers) => {
    if (values?.keimeno?.value?.length > 0) {
      addPostCommentsAction({
        postId: values.keimeno.postId,
        comment: values.keimeno.value,
        accessCode: accessCode,
      });
      formikHelpers.setValues({});
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = ({ postId, e }) => {
    setAnchorEl({ postId, anchorEl: e.currentTarget });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: post, isLoading } = useQuery({ queryKey: ["userPost", accessCode, id], queryFn: getFullPost });
  const { mutate: getPostCommentsAction } = useMutation({
    mutationFn: getPostComments,
    onSuccess: (data, variables, context) => {
      let newPost = post;
      if (data[0] && data[0].id_post) {
        if (newPost) {
          newPost["comments"] = data;
        }
      }
      queryClient.setQueryData(["userPost", accessCode, id], newPost);
    },
  });

  const { mutate: addPostCommentsAction } = useMutation({
    mutationFn: addPostComment,
    onSuccess: (data, variables, context) => {
      let newPost = post;
      if (newPost) {
        newPost.commentsNumber = (parseInt(newPost.commentsNumber) + 1).toString();
      }
      queryClient.setQueryData(["userPost", accessCode, id], newPost);
    },
  });

  const { mutate: deletePostCommentsAction } = useMutation({
    mutationFn: deletePostComments,
    onSuccess: (data, variables, context) => {
      let newPost = post;
      if (data.deleted === "1") {
        if (newPost) {
          const comments = newPost["comments"].filter(c => c.id_comment !== variables.commentId);
          newPost["comments"] = comments;
          newPost.commentsNumber = (parseInt(newPost.commentsNumber) - 1).toString();
        }
      }
      queryClient.setQueryData(["userPost", accessCode, id], newPost);
    },
  });
  const { mutate: deletePostAction } = useMutation({
    mutationFn: deletePost,
    onSuccess: (data, variables, context) => {
      history.push(getRouteUrl(ROUTE_PAGE_HOME));
    },
  });
  const { mutate: likePostAction } = useMutation({
    mutationFn: likePost,
    onSuccess: (data, variables, context) => {
      let newPost = post;
      if (variables.postId) {
        if (data.like === "added") {
          newPost.ihadLike = "1";
          newPost.likesNumber = (parseInt(newPost.likesNumber) + 1).toString();
        } else {
          newPost.ihadLike = "0";
          newPost.likesNumber = (parseInt(newPost.likesNumber) - 1).toString();
        }
      }
      queryClient.setQueryData(["userPost", accessCode, id], newPost);
      setAnchorEl(null);
    },
  });
  const deleteAction = ()=>{
    deletePostAction(isDeleteModalOpen)
    closeModal()
  }
  return (
    <FeedLayout>
      {!isLoading &&
        <MediaCard
          linkifiedText={linkify(post.description)}
          findYoutubeUrlString={findYoutubeUrl(post.description)}
          commentsNumber={post.commentsNumber}
          likesNumber={post.likesNumber}
          description={post.description}
          date={daysPassed(post.public_date) + "d"}
          setOpenModal={setOpenModal}
          postId={post.id}
          postUserId={post.id_user}
          getPostComments={getPostCommentsAction}
          assets={post.contents?.length > 1 ? post.contents.map(x => ({
            url: `https://dev.ostrialinked.gr/api/uploads/${x.filename}`,
            type: x.type,
            filename: x.filename,
          })) : null}
          asset={post.contents?.length === 1 ?
            {
              url: `https://dev.ostrialinked.gr/api/uploads/${post.contents[0].filename}`,
              type: post.contents[0].type,
              filename: post.contents[0].filename,
            } : null}
          onSubmit={onSubmit}
          comments={post.comments}
          userId={userId}
          deletePostComment={deletePostCommentsAction}
          handleClick={handleClick}
          likePost={likePostAction}
          ihadLike={post.ihadLike}
          type={post.type}
          imageProfile={post.type === "company" && post.company.id_user === post.user.id ? post.company.logo : post?.user?.image_profile}
          user={post.type === "company" && post.company.id_user === post.user.id ? {
            ...post.user, firstname: post.company.title, lastname: "",
            companyId: post.company.id,
          } : post.user}
        />
      }
      <OstriaModal
        open={Boolean(isModalOpen)}
        onClose={() => {
          setOpenModal(null);
        }}
      >
        <CardMedia
          component="img"
          image={isModalOpen}
          alt={isModalOpen}
        />
      </OstriaModal>
      <Menu
        id={`menu`}
        anchorEl={anchorEl?.anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            onClick={() => {
              openModal({ id_post: anchorEl.postId, accessCode })
              setAnchorEl(null)
            }}
            primary={t("delete")}
          />
        </ListItemButton>
      </Menu>
      <OstriaModal
        open={Boolean(isDeleteModalOpen)}
        onClose={closeModal}
      >
        <Box
          sx={{
            height: "auto",
            padding:'16px',
            margin: "auto",
          }}
        >
          <Typography
            sx={{
              color: "#050505",
              fontFamily: '"Segoe UI-Bold", Helvetica',
              fontSize: { xs: "15px", md: "21px" },
              fontWeight: "700",
              marginBottom: { xs: "5px", md: "10px" },
              textAlign:'center',
            }}
          >
            {g('Are you sure?')}
          </Typography>
          <Box className="reportButtons">
            <IconButton onClick={deleteAction}>
              <Check color="success"/>
              {g('yes')}
            </IconButton>
            <IconButton onClick={closeModal}>
              <Close sx={{color:'red'}}/>
              {g('no')}
            </IconButton>
          </Box>
        </Box>
      </OstriaModal>
    </FeedLayout>
  );
};