// @flow
import React, { useContext, useMemo } from "react";
import { MenuItem, MenuList, Paper, Typography } from "@mui/material";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import {
  ROUTE_PAGE_COMPANIES,
  ROUTE_PAGE_COMPANY_CENTER,
  ROUTE_PAGE_GROUP,
  ROUTE_PAGE_GROUPS,
  ROUTE_PAGE_HOME,
  ROUTE_PAGE_JOBS,
  ROUTE_PAGE_PERSONAL,
  ROUTE_PAGE_CHAT, ROUTE_PAGE_MOODLE, ROUTE_PAGE_NOTIFICATION, ROUTE_PAGE_NOTIFICATIONS_LIST,
} from "../../routers/routes";
import ListItemIcon from "@mui/material/ListItemIcon";
import Images from "../../assets";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import { PAGE_HOME } from "../../locales/pages/namespaces";
import { useHistory } from "react-router-dom";
import "./feedMenu.css";
import useUserData from "../../hooks/user/useUserData";
import {
  BankIcon,
  BuildingIcon,
  ChatNetworkIcon,
  EnvelopIcon,
  HomeIcon, NotificationIcon,
  SearchIcon,
  UserIcon,
} from "../../assets/toplayout/icons";
import { LocationContext } from "../../App";
import { locationPath, locations } from "../../helpers/constants";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

type Props = {};

const FeedMenu = (props: Props) => {
  const { t } = useTranslation(PAGE_HOME);
  const history = useHistory();
  const data = useUserData();
  const { value } = useContext(LocationContext);
  // console.log('data',data);
  const menu = useMemo(
    () => [
      {
        id: 1,
        url: getRouteUrl(ROUTE_PAGE_HOME),
        image: <HomeIcon  style={{width:'26px',height:"26px"}}/>,
        text: "home",
        alt: "home",
        className: "home-img",
        active:locationPath.root
      },
      // {
      //   id: 2,
      //   // url: getRouteUrl(ROUTE_PAGE_PERSONAL),
      //   url: getRouteUrl(ROUTE_PAGE_NOTIFICATIONS_LIST, {
      //     username: data.username,
      //   }),
      //   image: <NotificationIcon/>,
      //   text: "notification",
      //   alt: "notification",
      // },
      {
        id: 3,
        // url: getRouteUrl(ROUTE_PAGE_PERSONAL),
        url: getRouteUrl(ROUTE_PAGE_PERSONAL, {
          username: data.username,
        }),
        image: <UserIcon  style={{width:'26px',height:"26px"}}/>,
        text: "personal profile",
        alt: "profile",
      },
      {
        id: 4,
        url: getRouteUrl(ROUTE_PAGE_JOBS),
        image: <SearchIcon   style={{width:'26px',height:"26px"}}/>,
        text: "finding a job",
        alt: "job",
        active: locationPath.jobs,
      },
      {
        id: 5,
        url: getRouteUrl(ROUTE_PAGE_MOODLE),
        image: <BankIcon  style={{width:'26px',height:"26px"}}/>,
        text: "education platform",
        alt: "education",
        active: locationPath.education,
      },
      {
        id: 6,
        url: getRouteUrl(ROUTE_PAGE_CHAT),
        image: <EnvelopIcon  style={{width:'26px',height:"26px"}}/>,
        text: "messages",
        alt: "envelop",
        active: locationPath.messages,
      },
      {
        id: 7,
        url: getRouteUrl(ROUTE_PAGE_COMPANIES),
        image: <BuildingIcon  style={{width:'26px',height:"26px"}}/>,
        text: "building pages",
        alt: "building",
        active: locationPath.companies,
      },
      {
        id: 8,
        url: getRouteUrl(ROUTE_PAGE_GROUPS),
        image: <ChatNetworkIcon  style={{width:'26px',height:"26px"}}/>,
        text: "groups",
        alt: "groups",
        active: locationPath.groups,
      },
      {
        id: 9,
        url: getRouteUrl(ROUTE_PAGE_JOBS),
        image: <SearchIcon   style={{width:'26px',height:"26px"}}/>,
        text: "finding a job",
        alt: "job",
        active: locationPath.jobs,
      },
    ],
    [data.username]
  );

  return (
    <Paper elevation={0}>
      {menu.map((item) => (
        <MenuList key={item.id}>
          <MenuItem onClick={() => history.push(item.url)} style={{backgroundColor:value && locations[value] === item.active ? 'rgba(0, 0, 0, 0.04)': ''}}>
            <ListItemIcon>
              {item.image}
            </ListItemIcon>
            <ListItemText>
              <Typography color="primary" sx={{ paddingLeft: "1%" }}>
                {t(item.text)}
              </Typography>
            </ListItemText>
          </MenuItem>
        </MenuList>
      ))}
    </Paper>
  );
};

export default FeedMenu;
